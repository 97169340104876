import { Modal } from "react-bootstrap";
import ApiClient, { api_method } from "../utils/ApiClient";

function ModalConfig() {
  const client = new ApiClient();
  
  const token = sessionStorage.getItem("authorizeKey");
  
  // 기기삭제할 api
  const deleteDevice = () => {
    try {
      const data = 92;
      const response = client.RequestAsync(
        api_method.post,
        "/api/config/device/regist",
        null,
        data,
        token
      );
      if (response?.status === 200) {
        alert("기기를 삭제하였습니다.");
      } else {
        alert("아무일도 일어나지 않음.");
      }
    } catch (error) {
      console.error(`에러: ${error}`);
    }
  };

  deleteDevice();
  return (
    <Modal>
      <Modal.Header></Modal.Header>
      ss
    </Modal>
  );
}

export default ModalConfig;
