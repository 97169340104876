import axios from "axios";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import React, { useEffect, useState, useCallback } from "react";
import { Modal, Button } from "react-bootstrap";
import ModalConfig from "../lib/ModalConfig";
import "../styles/DeviceList.css";
import HRImg from "../asset/img/HRImg.png";
import BRImg from "../asset/img/BRImg.png";
import DisableImg from "../asset/img/DisableImg.png";
import MovingImg from "../asset/img/MovingImg.png";
import ReadyImg from "../asset/img/ReadyImg.png";
import MeasureImg from "../asset/img/MeasureImg.png";
import FV_Status_0 from "../asset/img/FV_Status_0.png";
import FV_Status_1 from "../asset/img/FV_Status_1.png";
import FV_Drop from "../asset/img/FV_Drop.png";
import FV_Edge from "../asset/img/FV_Edge.png";
import FV_Alert from "../asset/img/FV_Alert.png";
import FV_Floor from "../asset/img/FV_Floor.png";
import CV_Status_0 from "../asset/img/CV_Status_0.png";
import CV_Status_1 from "../asset/img/CV_Status_1.png";
import CV_Fall from "../asset/img/CV_Fall.png";
import { FiAlertCircle } from "react-icons/fi";
import ApiClient, { api_method } from "../utils/ApiClient";

function DeviceList() {
  const [deviceInfo, setDeviceInfo] = useState([]);
  const [deviceData, setDeviceData] = useState([]);
  const [deviceType, setDeviceType] = useState(null);
  const [selectedDevice, setSelectedDevice] = useState(null);
  const [stateImg, setStateImg] = useState(null);
  const [eggStateImg, setEggStateImg] = useState(null);
  const [FVHRData, setFVHRData] = useState(null);
  const [FVBRData, setFVBRData] = useState(null);
  const [eggHRData, setHREggData] = useState(null);
  const [eggBRData, setBREggData] = useState(null);
  const [FVStateImg, setFVStateImg] = useState(CV_Status_1);
  const [lastFetchData, setLastFetchData] = useState([]);
  const [stateColor, setStateColor] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [modifyModal, setModifyModal] = useState(false);
  const [ConfigModal, setConfigModal] = useState(false)


  
  const client = new ApiClient();
  const token = sessionStorage.getItem("authorizeKey");
  // 장치 목록 및 장치 정보 API
  const fetchDeviceInfo = async () => {
    try {
      const response = await client.RequestAsync(
        api_method.get,
        "/api/config/device/info",
        null,
        null,
        token
      );
      if (response?.status === 200) {
        // API 렉시컬 밖 호출
        setDeviceInfo(response.data);
      } else {
        throw new Error(`Failed to fetch device info (${response?.status})`);
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
  };

  const fetchRadarInfo = async () => {
    try {
      const response = await client.RequestAsync(
        api_method.get,
        "/api/config/device/radar/info",
        null,
        null,
        token
      );
      if (response?.status === 200) {
        // API 렉시컬 밖 호출
        const data = response.data;
        const sortData = [...data].sort((a, b) => b.deviceType - a.deviceType);
        const sortmapData = sortData.map((value) => value);
        setStateColor(sortmapData);
      } else {
        throw new Error(`Failed to fetch device info (${response?.status})`);
      }
    } catch (error) {
      console.error("Error fetching device info:", error);
    }
  };

  const fetchDeviceData = async () => {
    let promises = deviceInfo.map((device) =>
      axios.get(
        `http://api.bullseye.co.kr:7810/api/acqdata/lastest?device_id=${device.device_id}`,
        {
          headers: { Authorization: token },
        }
      )
    );
    Promise.all(promises)
      .then((responses) => {
        const allDeviceData = responses.map((response) => response.data);
        setLastFetchData(allDeviceData);
      })
      .catch((error) => {
        console.error(error);
      });
  };




  useEffect(() => {
    fetchDeviceInfo();
    fetchDeviceData();
    fetchRadarInfo();
  }, [token]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchDeviceInfo();
      fetchDeviceData();
      fetchRadarInfo();
    }, 5000); // 5초마다 데이터 업데이트
    return () => clearInterval(interval); // 컴포넌트가 언마운트될 때 타이머 정리
  }, [lastFetchData, deviceInfo]);
  
  // ========================================================================================================================================================================================================================
  let ConnectedCount = 0;
  lastFetchData.find((value) => {
    value.length == 1 ? ConnectedCount++ : (ConnectedCount += 0);
  });

  const deviceModalData = () => {
    if (deviceData.length > 0) {
      const deviceDataStatus = deviceData[deviceData.length - 1].data_value;
      // FV

      // console.log(deviceDataStatus.split(":"));
      if (deviceType === 14201) {
        if (deviceDataStatus.includes("ALERT")) {
          setFVStateImg(FV_Alert);
        } else if (deviceDataStatus.includes("DROP")) {
          setFVStateImg(FV_Drop);
        } else if (
          deviceDataStatus.split(":")[1].includes("VITAL") &&
          deviceDataStatus.split(":")[4].includes("CENTER") &&
          deviceDataStatus.split(":")[5].includes("NONE") == true
        ) {
          setFVStateImg(FV_Status_1);
        } else if (
          deviceDataStatus.includes("NOBODY") &&
          deviceDataStatus.includes("FLOOR") == true
        ) {
          setFVStateImg(FV_Floor);
        } else {
          setFVStateImg(FV_Status_0);
        }
        // CV
      } else if (deviceType == 14001) {
        // console.log(deviceDataStatus);
        // console.log(deviceDataStatus.includes("FALL"));
        if (deviceDataStatus.includes("FALL") == true) {
          setFVStateImg(CV_Fall);
        } else if (deviceDataStatus.includes("READY")) {
          setFVStateImg(CV_Status_1);
        } else {
          setFVStateImg(CV_Status_0);
        }
        // 에그
      } else if (deviceType == 14901) {
        if (deviceDataStatus.split(",")[13] == 0) {
          setFVStateImg(CV_Status_0);
        } else {
          setFVStateImg(CV_Status_1);
        }
      }
      // Egg

      if (deviceDataStatus.split(",")[13] == 3) {
        setEggStateImg(<img src={ReadyImg} alt="ReadyImg" />);
        setHREggData(deviceDataStatus.split(",")[18]);
        setBREggData(deviceDataStatus.split(",")[17]);
      } else if (deviceDataStatus.split(",")[13] == 2) {
        setEggStateImg(<img src={MeasureImg} alt="MeasureImg" />);
        setHREggData("--");
        setBREggData("--");
      } else {
        setEggStateImg(<img src={DisableImg} alt="DisableImg" />);
        setHREggData("--");
        setBREggData("--");
      }

      if (
        (deviceDataStatus.includes("VITAL") ||
          deviceDataStatus.includes("READY")) === true
      ) {
        setStateImg(<img src={ReadyImg} alt="ReadyImg" />);
        setFVHRData(
          deviceDataStatus
            .split(",")
            [deviceDataStatus.split(",").length - 1]?.replace("+", "")
        );
        setFVBRData(
          deviceDataStatus
            .split(",")
            [deviceDataStatus.split(",").length - 3]?.replace("+", "")
        );
      } else if (deviceDataStatus.includes("MOVING")) {
        setStateImg(<img src={MovingImg} alt="MovingImg" />);
        setFVHRData("--");
        setFVBRData("--");
      } else if (deviceDataStatus.includes("MEASURE")) {
        setStateImg(<img src={MeasureImg} alt="MeasureImg" />);
        setFVHRData("--");
        setFVBRData("--");
      } else {
        setStateImg(<img src={DisableImg} alt="DisableImg" />);
        setFVHRData("--");
        setFVBRData("--");
      }
    }
  };

  const convertTimestampToFormattedDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;
    return Number(formattedDate);
  };
  const convertCurrentDate = () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");
    const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}`;

    return Number(formattedDate);
  };

  const clickDeviceHandler = useCallback(
    async (deviceId) => {
      setSelectedDevice(deviceId);
      setTimeout(() => {
        setShowModal(true);
      }, 650);

      try {
        const deviceDataResponse = await client.RequestAsync(
          api_method.get,
          `/api/acqdata/lastest?device_id=${deviceId}`,
          null,
          null,
          token
        );
        const deviceTypeResponse = await client.RequestAsync(
          api_method.get,
          `/api/config/device/info?device_id=${deviceId}`,
          null,
          null,
          token
        );
        if (
          deviceDataResponse?.status === 200 &&
          deviceTypeResponse?.status === 200
        ) {
          const selectedDeviceInfo = deviceDataResponse.data.find(
            (device) => device.device_id === deviceId
          );
          if (deviceDataResponse.data == 0) {
          } else {
            // setDeviceResponse(selectedDeviceInfo)
            // setDeviceTResponse(deviceTypeResponse)
            setDeviceData(selectedDeviceInfo.datas);
            setDeviceType(deviceTypeResponse.data[0].device_type);
          }
        } else {
          throw new Error(
            `Failed to fetch device data (${deviceDataResponse?.status}) or device type (${deviceTypeResponse?.status})`
          );
        }
      } catch (error) {
        console.error("Error fetching device data:", error);
      }
    },
    [token] // 종속성 배열에 token을 추가합니다.
  );
  const startPolling = () => {
    const interval = setInterval(async () => {
      // fetchDeviceCountInfo(selectedDevice);
      if (selectedDevice) {
        try {
          const deviceDataResponse = await client.RequestAsync(
            api_method.get,
            `/api/acqdata/lastest?device_id=${selectedDevice}`,
            null,
            null,
            token
          );
          if (deviceDataResponse?.status === 200) {
            const selectedDeviceInfo = deviceDataResponse.data.find(
              (device) => device.device_id === selectedDevice
            );
            setDeviceData(selectedDeviceInfo.datas);
          } else {
            throw new Error(
              `Failed to fetch device data (${deviceDataResponse?.status})`
            );
          }
        } catch (error) {
          console.error("Error fetching device data:", error);
        }
      }
    }, 5000); // 5초마다 데이터 업데이트

    return () => clearInterval(interval); // 클린업 함수 반환
  };

  // 첫 렌더시 비동기 함수 장치 정보 API 호출

  // 첫 렌더시 모달 데이터 정보 API 호출
  useEffect(() => {
    deviceModalData();
  }, [deviceData, deviceType]);

  useEffect(() => {
    const stopPolling = startPolling();
    return () => stopPolling();
  }, [selectedDevice]);
  // 장치 목록 렌더함수(deviceID만큼 생성 및 is_enable로 불량장치 구별)
  const renderDeviceRectangles = () => {
    if (deviceIds.length === 0) {
      return (
        <div
          style={{
            fontSize: "19px",
            fontWeight: 500,
            textAlign: "center",
            margin: "90px 0px 0px 400px",
          }}
        >
          등록된 장치가 없습니다
        </div>
      );
    }

    // convertTimestampToFormattedDate()
    // deviceInfo 배열을 device_type 기준으로 내림차순 정렬
    const sortedDeviceInfo = [...deviceInfo].sort(
      (a, b) => b.device_type - a.device_type
    );

    // 정렬된 순서에 맞게 deviceIds 배열도 재정렬
    // lastFetchData.map((value)=>value[0]==undefined? arr.push({device_id:'0'}) : arr.push(value[0]))

    const sortedDeviceIds = sortedDeviceInfo.map((info) => info.device_id);


    sortedDeviceIds.map((deviceId, index) => {
      // console.log(stateColor && stateColor[index] && convertCurrentDate() - convertTimestampToFormattedDate(stateColor[index].lastestUpdate))
    })


    return sortedDeviceIds.map((deviceId, index) => (
      <div
        className="device-rectangle"
        key={deviceId}
        onClick={() => clickDeviceHandler(deviceId)}
        style={{
          width: "76px",
          height: "76px",
          // 에러남!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
          backgroundColor: `${
            stateColor &&
            stateColor[index] &&
            convertCurrentDate() -
              convertTimestampToFormattedDate(stateColor[index].lastestUpdate) >
              120
              ? "#ededed"
              : stateColor &&
                stateColor[index] &&
                (stateColor[index].deviceStatus == "NONE" ||
                  stateColor[index].deviceStatus == "NOBODY" ||
                  stateColor[index].deviceStatus == "can't Parse")
              ? "#fff"
              : stateColor &&
                stateColor[index] &&
                stateColor[index].deviceStatus == "MOVING"
              ? "#d2e6fa"
              : stateColor &&
                stateColor[index] &&
                (stateColor[index].deviceStatus == "READY" ||
                  stateColor[index].deviceStatus == "VITAL")
              ? "#d9f7c6"
              : stateColor &&
                stateColor[index] &&
                stateColor[index].deviceStatus == "MEASURE"
              ? "#d2e6fa"
              : stateColor &&
                stateColor[index] &&
                (stateColor[index].deviceStatus == "RDROP" ||
                  stateColor[index].deviceStatus == "LDROP" ||
                  stateColor[index].deviceStatus == "FALL" ||
                  stateColor[index].deviceStatus == "LALERT" ||
                  stateColor[index].deviceStatus == "RALERT")
              ? "#fc2d2d"
              : "gold"
          }`,
          borderRadius: "9px",
          // 원래 60인데 80으로 넉넉하게 잡음 나중에 함 볼것 1분차이
          // opacity: (convertCurrentDate()-convertTimestampToFormattedDate(stateColor[index].lastestUpdate))> 120 ? 0.5 : 1,
          pointerEvents:
          stateColor &&
          stateColor[index] &&
            convertCurrentDate() -
              convertTimestampToFormattedDate(stateColor[index].lastestUpdate) >
            120
              ? "none"
              : "auto",
          border:
          stateColor &&
          stateColor[index] &&
            convertCurrentDate() -
              convertTimestampToFormattedDate(stateColor[index].lastestUpdate) >
            120
              ? "none"
              : "2px solid #dddddd",
          cursor: "pointer",
          textAlign: "center",
          justifyContent: "center",
          lineHeight: "76px",
          fontWeight: "bold",
          position: "relative",
          color:
          stateColor &&
          stateColor[index] &&
            convertCurrentDate() -
              convertTimestampToFormattedDate(stateColor[index].lastestUpdate) >
            120
              ? "black"
              : "#191919",
          fontSize: "12px",
          wordBreak: "keep-all",
          margin: "9px",
        }}
      >
        {sortedDeviceInfo[index].device_name}
        {/* <div>{console.log('1. ',convertCurrentDate())}</div> */}
        {/* <div>{console.log('2. ',convertTimestampToFormattedDate(stateColor[index].lastestUpdate))}</div> */}
        {/* <div>{console.log(convertCurrentDate()-convertTimestampToFormattedDate(stateColor[index].lastestUpdate))}</div> */}
      </div>
    ));
  };

  // 장치 목록의 device_id 배열 생성
  const deviceIds = deviceInfo.map((device) => device.device_id);

  const Tooltip = ({ message }) => {
    return (
      <div className="tooltip">
        <div>장치 목록에서의 색 변화는 장치의 상태정보를 제공합니다.</div>
        <span> ■ : 비활성</span>
        <span> ■ : 부재중</span>
        <span> ■ : 움직임(측정중)</span>
        <span> ■ : 재실</span>
        <span> ■ : 이벤트</span>
      </div>
    );
  };
  return (
    <div className="MainContents">
      <Container fluid style={{ marginLeft: 0, padding: 0 }}>
        <Row style={{ marginLeft: "30px" }}>
          <div
            style={{
              width: "1028px",
              height: "276px",
              padding: "0px",
              marginTop: "26px",
              background: "#ffffff",
            }}
          >
            <h6
              style={{
                fontWeight: "500",
                fontSize: "18.5px",
                background: "#ffffff",
                padding: "11px 0px 0px 20px",
                width: "100%",
                height: "8%",
              }}
            >
              장치 목록{" "}
              <div
                className="icon-container"
                onMouseEnter={() => setShowTooltip(true)}
                onMouseLeave={() => setShowTooltip(false)}
              >
                <FiAlertCircle
                  cursor="pointer"
                  className="fallStatus-Tooltip"
                  size={17}
                />
                {showTooltip && <Tooltip />}
              </div>
              {/* <button onClick={()=>{setModifyModal(true)}} style={{float:'right', display:'inline-block',paddingRight:'20px', background: 'red'}}>편집</button> */}
            </h6>
            <div
              className="device-rectangle-container"
              style={{
                background: "#fff",
                width: "95%",
                height: "76%",
                display: "flex",
                flexWrap: "wrap",
                alignItems: "flex-start",
                marginLeft: "30px",
                paddingTop: "10px",
                overflowY: "auto",
              }}
            >
              <div
                className="device-rectangle"
                style={{
                  display: "flex",
                  alignItems: "center",
                  display: "flex",
                  flexWrap: "wrap",
                }}
              >
                {renderDeviceRectangles()}
                {/* 장치목록 파란색 아이콘 사각형 */}
              </div>
            </div>
          </div>

          <div
            style={{
              width: "536px",
              margin: "26px 31px 0px 32px",
              background: "#ffffff",
            }}
          >
            <div
              style={{
                fontWeight: "500",
                fontSize: "18.5px",
                margin: "11px 0px 10px 10px  ",
              }}
            >
              장치 정보
            </div>
            <div>
              <div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "20px 0px",
                    borderBottom: "1px solid #ededed",
                  }}
                >
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      backgroundColor: "#ededed",
                      margin: "0px 0px 0px 58px",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <div
                    style={{
                      marginLeft: "94px",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    전체 장치 수
                  </div>
                  <div
                    style={{
                      marginLeft: "160px",
                      fontWeight: "500",
                      fontSize: "19px",
                    }}
                  >
                    {deviceInfo.length} 개
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "20px 0px",
                    borderBottom: "1px solid #ededed",
                  }}
                >
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      backgroundColor: "#d2e6fa",
                      margin: "0px 0px 0px 58px",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <div
                    style={{
                      marginLeft: "94px",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    정상 장치 수
                  </div>
                  <div
                    style={{
                      marginLeft: "160px",
                      fontWeight: "500",
                      fontSize: "19px",
                    }}
                  >
                    {ConnectedCount} 개
                  </div>
                </div>
                <div
                  style={{
                    fontWeight: "bold",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                    padding: "20px 0px",
                  }}
                >
                  <div
                    style={{
                      width: "36px",
                      height: "36px",
                      backgroundColor: "#d60225",
                      margin: "0px 0px 0px 58px",
                      borderRadius: "4px",
                    }}
                  ></div>
                  <div
                    style={{
                      marginLeft: "94px",
                      fontWeight: "500",
                      fontSize: "18px",
                    }}
                  >
                    불량 장치 수
                  </div>
                  <div
                    style={{
                      marginLeft: "160px",
                      fontWeight: "500",
                      fontSize: "19px",
                    }}
                  >
                    {deviceInfo.length - ConnectedCount} 개
                  </div>
                </div>
              </div>
              {/* (
                <div style={{fontSize:'18px',fontWeight:'500',textAlign:'center',lineHeight:'180px'}}>장치 정보를 불러오고 있습니다.</div>
              )} */}
            </div>
          </div>
        </Row>
      </Container>
      {/* 모달 */}
      <Modal
        style={{ borderRadius: "10px" }}
        show={showModal}
        onHide={() => setShowModal(false)}
        
      >
        <Modal.Header className="device-info-modal-header" closeButton>
          <div className="header-title">장치 정보</div>
        </Modal.Header>
        <Modal.Body className="device-info-modal-body">
          {/* FV, CV 공용 데이터 */}
          <div className="Modal-container">
            <div className="deviceImg">
              {/* 침대 상태 이미지(존재, 낙상, 미존재) */}
              <img src={FVStateImg} alt="deviceModal" />
            </div>
            <div className="deviceInfo">
              <h4>
                {
                  deviceInfo.find(
                    (device) => device.device_id === selectedDevice
                  )?.device_name
                }
              </h4>
              {/* CV */}
              {deviceType === 14001 && (
                <>
                  {/* CV MAIN */}
                  {/* CV TITLE */}
                  <h5>HRS_R8A_E_CV</h5>
                  {/* CV STATE IMG */}
                  <div className="STATE-container">
                    {deviceData.length > 0 ? stateImg : null}
                  </div>
                  {/* CV HRBR-CONTAINER */}
                  {deviceData && deviceData.length > 0 && (
                    <div className="HR-BR-container">
                      <div className="HR-container">
                        <img src={HRImg} alt="HRImage" className="HRimage" />
                        <span>{FVHRData}</span>
                        <span className="bpm-title">BPM</span>
                      </div>

                      <div className="BR-container">
                        <img src={BRImg} alt="BRImage" className="BRimage" />
                        <span>{FVBRData}</span>
                        <span className="bpm-title">BPM</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {deviceType === 14201 && (
                <>
                  {/* FV */}
                  {/* FV MAIN */}
                  {/* FV TITLE */}
                  <h5>HRS_R8A_E_FV</h5>
                  {/* FV STATE IMG */}
                  <div className="STATE-container">{stateImg}</div>
                  {/* FV HRBR-CONTAINER */}
                  {deviceData && deviceData.length > 0 && (
                    <div className="HR-BR-container">
                      <div className="HR-container">
                        <img src={HRImg} alt="HRImage" className="HRimage" />
                        <span>{FVHRData}</span>
                        <span className="bpm-title">BPM</span>
                      </div>

                      <div className="BR-container">
                        <img src={BRImg} alt="BRImage" className="BRimage" />
                        <span>{FVBRData}</span>
                        <span className="bpm-title">BPM</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              {deviceType === 14901 && (
                <>
                  {/* FV */}
                  {/* FV MAIN */}
                  {/* FV TITLE */}
                  <h5>탁상형 DEVICE</h5>
                  {/* FV STATE IMG */}
                  <div className="STATE-container">{eggStateImg}</div>
                  {/* FV HRBR-CONTAINER */}
                  {deviceData && deviceData.length > 0 && (
                    <div className="HR-BR-container">
                      <div className="HR-container">
                        <img src={HRImg} alt="HRImage" className="HRimage" />
                        <span
                          style={{ display: "inline-block", width: "52px" }}
                        >
                          {eggHRData}
                        </span>
                        <span className="bpm-title">BPM</span>
                      </div>

                      <div className="BR-container">
                        <img src={BRImg} alt="BRImage" className="BRimage" />
                        <span
                          style={{ display: "inline-block", width: "52px" }}
                        >
                          {eggBRData}
                        </span>
                        <span className="bpm-title">BPM</span>
                      </div>
                    </div>
                  )}
                </>
              )}

              <button className="closeBtn" onClick={() => setShowModal(false)}>
                닫기
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        style={{ borderRadius: "10px" }}
        show={modifyModal}
        onHide={() => setModifyModal(false)}
        
      >
        <Modal.Header  closeButton>
        </Modal.Header>
            <div style={{padding:'20px',height:'300px'}}>
            <div onClick={()=><ModalConfig/>} style={{background:'red', display:'block', cursor:'pointer'}}>장치 추가</div>
            <div onClick={()=>{
              alert('clicked')
        
              }} style={{background:'yellow', display:'block', cursor:'pointer'}}>장치 삭제</div>

            </div>
      </Modal>
    </div>
  );
}

export default DeviceList;
